.App {
  text-align: center;
  font-family: "Press Start 2P", system-ui;
  font-weight: 400;
  font-style: normal;
  background-color: #3C1053;
  color: #F4AF23;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  /*centers items on the line (the x-axis by default)*/
  align-items: center;
  /*centers items on the cross-axis (y by default)*/
}

.grid-container {
  position: absolute;
  width: 200%;
  height: 100vh;
  bottom: 0;
  left: -50%;
  overflow: hidden;
  transform: perspective(200px) rotateX(40deg) scale(1) translateZ(0);
  transform-origin: bottom;
  padding: 1px;
  -webkit-background-clip: content-box;
  -webkit-backface-visibility: hidden;
  outline: 1px solid transparent;
  will-change: transform;
}

.grid-line {
  height: 100%;
  width: 100%;
  position: absolute;
}


.grid-line:before,
.grid-line:after {
  content: "";
  display: block;
  position: absolute;
}

.grid-line:before {
  height: 5px;
  width: 100%;
  background: #05C3DD;
}

.grid-line:after {
  height: 100%;
  width: 5px;
  background-image: linear-gradient(transparent, #05C3DD);
}

.grid-line:nth-child(1):before {
  top: 0%;
  opacity: 0;
}

.grid-line:nth-child(2):before {
  top: 10%;
  opacity: 0;
}

.grid-line:nth-child(3):before {
  top: 20%;
  opacity: .3;
}

.grid-line:nth-child(4):before {
  top: 30%;
  opacity: .4;
}

.grid-line:nth-child(5):before {
  top: 40%;
  opacity: .5;
}

.grid-line:nth-child(6):before {
  top: 50%;
  opacity: .6;
}

.grid-line:nth-child(7):before {
  top: 60%;
  opacity: .7;
}

.grid-line:nth-child(8):before {
  top: 70%;
  opacity: .8;
}

.grid-line:nth-child(9):before {
  top: 80%;
  opacity: .9;
}

.grid-line:nth-child(10):before {
  top: 90%;
  opacity: 1;
}

.grid-line:nth-child(11):before {
  top: calc(100% - 3px);
}


.grid-line:nth-child(1):after {
  left: 0%;
}

.grid-line:nth-child(2):after {
  left: 10%;
}

.grid-line:nth-child(3):after {
  left: 20%;
}

.grid-line:nth-child(4):after {
  left: 30%;
}

.grid-line:nth-child(5):after {
  left: 40%;
}

.grid-line:nth-child(6):after {
  left: 50%;
}

.grid-line:nth-child(7):after {
  left: 60%;
}

.grid-line:nth-child(8):after {
  left: 70%;
}

.grid-line:nth-child(9):after {
  left: 80%;
}

.grid-line:nth-child(10):after {
  left: 90%;
}

.grid-line:nth-child(11):after {
  left: calc(100% - 3px);
}

.start-game-button {
  z-index: 100;
  animation: pulse 5s ease-in infinite;
}

.game-sub-header {
  color: #4AC9E3;
  font-size: large;
  margin: 24px 0 48px 0;
}

@keyframes pulse{
  25%  {transform: scale(0.9);}
  75%  {transform: scale(1.1);}
}

.button {
  font-size: xx-large;
}

.button:hover {
  cursor: pointer;
  color: #F3EA5D;
}

.text-area {
  padding: 8px;
  width: 600px;
  height: 200px;
}

.input-text-area {
  border: 4px #F4AF23 solid;
  background-color: #3C1053;
  color: #F4AF23;
}

.input-text-area:focus-visible {
  outline: none;
}

.result-header {
  display: inline-block;
  min-width: 150px;
  max-width: 150px;
}

.result-value {
  display: inline-block;
  min-width: 50px;
  max-width: 50px;
}

.hint {
  color: #E10098;
  margin-bottom: 24px;
}

.name-input {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: "Press Start 2P", system-ui;
  padding: 4px !important;
  color: #F4AF23;
  border: 4px #F4AF23 solid;
  font-weight: 400;
  background-color: #3C1053;
  min-width: 244px;
  max-width: 244px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.name-input:focus-visible {
  outline: none;
}

.leaderboard {
  margin: auto;
  border: 4px #F4AF23 solid;
  background-color: #3C1053;
  padding: 24px;
  margin-top: -200px;
  margin-bottom: 48px;
}

.leaderboard-number-header {
  min-width: 200px;
  max-width: 200px;
}

.leaderboard-text-header {
  min-width: 250px;
  max-width: 250px;
}

.leaderboard-header {
  font-size: large;
  font-weight: 700;
  display: inline-block;
  margin: 24px 0 8px 0;
}

.leaderboard-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaderboard-number-item {
  min-width: 200px;
  max-width: 200px;
}

.leaderboard-text-item {
  min-width: 250px;
  max-width: 250px;
}

.leaderboard-item {
  display: inline-block;
  font-size: small;
  margin: 4px 0 4px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.leaderboard-input {
  text-align: center;
  font-family: "Press Start 2P", system-ui;
  margin: 4px 0 4px -4px;
  padding: 4px !important;
  color: #F4AF23;
  border: 2px #F4AF23 solid;
  font-weight: 400;
  background-color: #3C1053;
  min-width: 244px;
  max-width: 244px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.leaderboard-input:focus-visible {
  outline: none;
}

@media only screen and (max-width: 1400px) {
  .App {
    font-size: x-small;
  }

  .leaderboard {
    width: 100%;
    padding: 8px;
    margin-top: -100px;
  }

  .leaderboard-header {
    font-size: medium;
    margin: 24px 0 12px 0;
    min-width: 200px;
    max-width: 200px;
  }

  .leaderboard-number-header {
    min-width: 150px;
    max-width: 150px;
  }
  
  .leaderboard-text-header {
    min-width: 250px;
    max-width: 250px;
  }


.leaderboard-number-item {
  min-width: 150px;
  max-width: 150px;
}

.leaderboard-text-item {
  min-width: 250px;
  max-width: 250px;
}

  .leaderboard-input {
    min-width: 194px;
    max-width: 194px;
    font-size: x-small;
  }
}